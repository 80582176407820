import React from "react"

export default function PrivacyMain({ children, data }) {
  return (<div className="privacy-wrapper">
		{/* <h1>Privacy Policy
        <br/>We had to put the nitty gritty somewhere </h1> */}

        <div className="privacy-content_wrapper">
            <h2>Czarnowski Display Service, Inc. d/b/a Public School Agency Website Privacy Statement</h2>
            <p>This privacy policy document was last updated on 3/01/2022. </p>
            <ul>
                <li>Acknowledgement and Acceptance of Term</li>
                <li>Participating Merchant Policies</li>
                <li>What is personal information?</li>
                <li>Information we collet and from what sources</li>
                <li>How do we use the personal information we collect?</li>
                <li>What personal information does Public School disclose or sell to others?</li>
                <li>Your ability to control your personal information</li>
                <li>If you are a California resident</li>
                <li>Children under age 16</li>
                <li>Security</li>
                <li>Acceptance of and changes to our Privacy Policy </li>
            </ul>

            <h2>1. Acknowledgement and acceptance of terms</h2>
            <p>Czarnowski Display Service, Inc d/b/a Public School Agency (“Public School”) is committed to protecting your privacy. This Privacy Policy sets forth our current privacy practices with regard to collection, use, disclosure and sale of personal information both with respect to:</p> 

            <p>(a) our public website, and; (b) the services provided to our customers.   This Privacy Policy applies to information we collect: </p>
            <ul>
                <li>On this website;</li>
                <li>In email, text, and other electronic messages between you and this website;</li>
                <li>When you interact with our advertising and applications on third-party websites and services, if those applications or advertisements include links to this policy. </li>
            </ul>

            <p className="topSpace">This Privacy Policy does not apply to information collected offline or through any other means. </p>

            <p>By accessing Public School / www.public-school.com, you acknowledge and fully understand Public School’s Privacy Policy and freely consent to the information collection and use practices described in this Privacy Policy.  We reserve the right to amend this privacy notice at our discretion and at any time.  Your continued use of this website after we make changes is deemed to be acceptance of those changes, so please check the Privacy Policy periodically for updates. </p>
       
            <h2>2. Participating merchant policies</h2>   
            <p>This website includes links to third party sites, each of which has its own privacy statements that can be viewed within each respective website. Except for our service providers, Czarnowski has no direct control over the policies or practices of participating merchants and other third parties and we are not responsible for the privacy practices or contents of those sites. We recommend and encourage that you always review the privacy policies of merchants and other third parties before you provide any personal information or complete any transaction with such parties. </p>
        
            <h2>3. What is personal information</h2>   
            <p>When we speak of “personal information”, we are referring to the type of information that can be used to identify a person or a household or that can be reasonably associated with a person or household.  For example, the following categories are types of personal information:</p>

            <ul>
                <li>Identifiers such as: real name, alias, postal address, unique personal identifier, online identifier, internet protocol address, email address, account name or other similar identifiers;</li>
                <li>Biometric information;</li>
                <li>Internet or other electronic network activity information such as: browsing history, search history, and information regarding your interaction with an internet website, application, or advertisement;</li>
                <li>Geolocation information;</li>
                <li>Professional or employment-related information;</li>
                <li>Education information that is not publicly available personally identifiable information under the Federal Educational Rights and Privacy Act;</li>
            </ul>
            
            <h2>4. Information we collect and from what sources </h2>   

            <p>Public School collects different types of personal information from different sources:</p> 

            <p className="underline">Personal information collected from individuals:</p>

            <p>When individuals visit our publicly accessible website, Public School collects personal information from those individuals if they choose to provide this information to us.  Individuals may decide to provide their personal information to us if they are interested in our services, if they have questions about the company, if they subscribe to our blog, if they are interested in employment, or for some other reason.  The types of personal information that we may collect from individuals, if they choose to provide it, include the following:  (a) personal identifiers (e.g., first and last name, address, business e-mail address, and telephone number); (b) professional information (e.g., the name of the organization with which the individual is associated and their job title); (b) their resume information and employment application information; and (c) any other personal information that the individual may voluntarily provide to us in an online form.</p> 

            <p className="underline">Via automated tools: Public School collects certain information from and about its website users via automated tools. </p>

            <p>a. <span className="underline">Web Server Logs<br/></span>When you visit our website, we may track information to administer the site and analyze its usage. Examples of information we may track include:
            </p>
            <ul>
                <li>Your Internet protocol address.</li>
                <li>Name of your Internet service provider.</li>
                <li>The kind of browser or computer you use.</li>
                <li>Number of links you click within the site.</li>
                <li>State or country from which you accessed the site.</li>
                <li>Date and time of your visit.</li>
                <li>Web page you linked to our site from.</li>
                <li>Pages you viewed on the site.</li>
            </ul>
            
            <p className="topSpace">b. <span className="underline">Use of Cookies</span></p>

            <p>Public School may use cookies to personalize or enhance your user experience. A cookie is a small text file that is placed on your hard disk by a web page server. Cookies cannot be used to run programs or deliver viruses to your computer. Cookies are uniquely assigned to you, and can only be read by a web Server in the domain that issued the cookie to you.</p>

            <p>This information is used for internal purposes only.  One of the primary purposes of cookies is to provide a convenience feature to save you time. For example, if you personalize a web page, or navigate within a site, a cookie helps the site to recall your specific information on subsequent visits. Thus, this simplifies the process of delivering relevant content and eases site navigation by providing and saving your preferences and login information as well as providing personalized functionality. </p>

            <p>Public School reserves the right to share aggregated site statistics with partner companies, but does not allow other companies to place cookies on our website unless there is a temporary, overriding customer value (such as the merging into Public School.com of a site that relies on third- party cookies). </p>

            <p>You can accept or decline cookies. Most web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies. If you reject cookies by changing your browser settings, be aware that this may disable some of the functionality on our website. </p>

            <p className="topSpace">c. <span className="underline">Use of Web Beacons or GIF Files</span></p>

            <p>Public School / www.public-school.com web pages may contain electronic images known as web beacons – sometimes also called single-pixel gifs – that allow Public School to count users who have visited those pages and to deliver co-branded services. Public School may include web beacons in promotional e-mail messages or newsletters in order to determine whether messages have been opened and acted upon. Some of these web beacons may be placed by third party service providers to help determine the effectiveness of our advertising campaigns or email communications. These web beacons may be used by these service providers to place a persistent cookie on your computer. This allows the service provider to recognize your computer each time you visit certain pages or emails and compile anonymous information in relation to those page views, which in turn enables us and our service providers to learn which advertisements and emails bring you to our website and how you use the site. Public School prohibits web beacons from being used to collect or access your personal information.</p> 
                    
            <h2>5. How do we use the personal information we collect</h2>   

            <p className="underline">Personal Information collected through our publicly accessible website:</p>

            <p>Public School uses the personal information collected from individuals when they browse our publicly accessible website for the following purposes:  (a) to contact prospective clients and others who request that we contact them; (b) to respond to questions, concerns, and comments submitted to us by the individuals; (c) to send our blog updates and other information to individuals who indicate an interest; (d) to inform individuals of modification and updates to the Services and to communicate other announcements about Public School and our Services; (e) to invite you to participate in user testing and surveys; (f) to identify usage trends and develop data analysis, including for purposes of audit, reporting and other business operations; and, (g) to comply with legal obligations, resolve disputes and enforce our agreements. </p>

            <h2>6. What personal information does public school disclose or sell to others</h2>   

            <p><span className="underline">Sale of Personal Information:</span>  Public School does not sell personal information. </p> 

            <p><span className="underline">Disclosure of Personal Information:</span>  Public School may disclose personal information as necessary or appropriate in connection with any of the purposes for which we use personal information as described above in “How Do We Use the Personal Information We Collect”.</p> 

            <p>These third parties include service providers that help host or support the website or otherwise provide technical assistance as well as companies we hire to manage the employment applications we receive.  Public School transfers to these service providers only the personal information they need to deliver to Public School the product or service we contracted for.  Public School prohibits these service providers from using that information for any other purpose.  Public School requires that these service providers maintain commercially reasonable measures to protect the confidentiality of your information. </p>

            <p>Public School also may in good faith disclose personal information for any of the following purposes: (i) to investigate, prevent or take action regarding actual or suspected illegal activities or fraud and situations involving potential threats to the physical safety of any person; (ii) responding to or defending against subpoenas, court orders, or other legal process; (iii) establishing or exercising Public School’s legal rights; or (iv) otherwise complying with applicable law.</p>

            <h2>7. Your ability to control your personal information</h2>
            
            <p><span className="underline">Right to review and update your personal information:</span> Public School strives to protect the quality and integrity of your personal information. If you have provided your personal information to Public School while browsing Public School’s publicly accessible website, and wish to update such personal information that you have provided us, please e-mail us at <a href="mailto:compliance@czarnowski.com">compliance@czarnowski.com</a>.</p>

            <p><span className="underline">Right to unsubscribe:</span> If you wish to unsubscribe from receipt of our blog or other communications for which you have registered, you may e-mail us at <a href="mailto:compliance@czarnowski.com">compliance@czarnowski.com</a>.</p> 

            <p>In limited circumstances, you may have the right to have your information transmitted to another entity or person in a machine-readable format.</p> 

            <p>There may be exceptions that apply to your request.</p> 

            <p>To exercise your rights, you or your authorized representative may submit a request to <a href="mailto:compliance@czarnowski.com">compliance@czarnowski.com</a> or by contacting us using one of the methods listed in the Contact section.</p> 

            <p>You will not be discriminated against for exercising any of your rights.</p>

            <h2>8. If you are a california resident </h2>

            <p>In addition to the information provided above, California residents have additional legal rights established by the California Consumer Privacy Act of 2018, as amended.  Those rights include the following: </p>

            <p><span className="underline">Right to Know:</span> California law grants its residents a right to request, twice in each 12-month period, that Public School disclose to you what personal information we collect, use, disclose, and sell about you.  If you want to make a “request to know”, 
            The process for requesting the “Right to Know” should be sent via email to <a href="mailto:compliance@czarnowski.com">compliance@czarnowski.com</a> and include the requestor’s full name, phone number and the relationship(s) the requestor has with Public School. 
            The business will log and record the request and notify the legal and compliance department. Compliance will first authenticate that the requestor is who they say they are. This will be done through secondary requests from the compliance team to the requestor. Compliance will work with HR and the IT departments to query any and all references to the requestor and compile the data references to the requestor. The information will be sent back to the requestor via email. </p>

             <p><span className="underline">Right to Request Deletion:</span> California law grants its residents a right to request the deletion of their personal information that Public School has collected directly from you.  The right to request deletion is not absolute; the law allows businesses to keep personal information for certain purposes set forth in the law. 
            The process for requesting the “Right to Delete” should be sent via email to <a href="mailto:compliance@czarnowski.com">compliance@czarnowski.com</a> and include the requestor’s full name, phone number and the relationship(s) the requestor has with Public School. 
             The business will log and record the request and notify the compliance department. Compliance will first authenticate that the requestor is who they say they are. This will be done through secondary requests from the compliance team to the requestor. Compliance will work with HR and the IT departments to query any and all references to the requestor and confirm the data can be removed. For the data that can be deleted, HR and IT will confirm its deletion to the compliance team and the compliance team will communicate to the requestor. As indicated in the law, there is some information that may not be deleted, such as employment history. </p>

            <p><span className="underline">Right Against Non-Discrimination:</span> California law prohibits Public School from discriminating against you based on your exercise of the privacy rights conferred upon you by California law and described above. </p>

            <p><span className="underline">Right to Authorized Agent:</span> California law allows you to designate an agent to make a “request to know” and “request to op-out” on your behalf. 
            The process for “Designating an Agent” should be sent via email to <a href="mailto:compliance@czarnowski.com">compliance@czarnowski.com</a> and include the requestor’s full name, phone number signed written permission specifying both the requestor and the designated agent. The compliant department will verity both the requestor and the agent. </p>
       
            <h2>9. Children under age 16 </h2>   
            
            <p>This website is not intended for children under 16 years of age. No one under age 16 may provide any information to or on the website. Public School does not knowingly collect personal information from children under 16.  If you are under 16, do not provide any information on this website or on or through any of its features, use any of the interactive of this website or provide any information about yourself to us, including your name, address, telephone number, email address, or any screen name or user name you may use. If Public School learns we have collected or received personal information from a child under 16 without verification of parental consent, we will delete that information. If you believe Public School might have any information from or about a child under 16, please contact us via the contact methods outlined below. </p>

            <h2>10. Security</h2>   

            <p>The security of the information we collect is important to us, but no method of transmission over the Internet, or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect the information we collect, we cannot guarantee its absolute security. </p>
        
            <h2>11. Contacting us</h2>
            <p>If you have questions regarding our Privacy Policy, its implementation, failure to adhere to this Privacy Statement and/or our general practices, please contact us at <a href="mailto:compliance@czarnowski.com">compliance@czarnowski.com</a> or send your comments to : </p>

            <p>Czarnowski Display Service, Inc. d/b/a Public School Agency<br/>
            Attention: Privacy Statement Personnel and Marketing Department<br/>
            2287 South Blue Island Ave<br/>
            Chicago, IL 60608 </p> 

            <p>Public School will use commercially reasonable efforts to promptly respond and resolve any problem or question. </p>
        </div>
  </div>);
}
