import React from 'react'
import Layout from '../components/Layout'
import { Helmet } from 'react-helmet';
import PrivacyMain from '../components/privacy/PrivacyMain'
import Mouse from '../components/mouse/Mouse'

const PrivacyTemplate = props => {
    return (
      <Layout data={props} pageID={'page-privacy'}>
        <Helmet>
          <meta name="viewport" content="width=device-width; initial-scale=1.0; maximum-scale=1.0; user-scalable=0;" />
          <meta charSet="utf-8" />
          <title>Privacy Policy - Public School</title>
          <meta name="description" content="We are a creative studio that helps brands find their way through new audiences and culture." />
          <meta name="referrer" content="origin" />
        </Helmet>

        <section className="container privacy-page">
          <PrivacyMain data={props}/>
        </section>
        
        <Mouse />

      </Layout>
    )
}

export default PrivacyTemplate
